import React from 'react';
import clsx from 'clsx';
import type { HTMLAttributes } from 'react';
import { Spinner } from "@nextui-org/spinner";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/modal";
import Img from '@react/widget/Img';
import Icon from '@react/widget/Icon';
import Button from "@components/widget/Button.astro";

const playSizes = {
  "sm": "16",
  "md": "32"
};

interface Props extends HTMLAttributes<HTMLDivElement> {
  url?: string;
  vimeoUrl?: string;
  playSize?: keyof typeof playSizes;
  image?: Image;
  loading?: "lazy" | "eager";
  onlyPlayicon?: boolean;
  buttonPlay?: boolean;
  btntext?: string;
  videoStyle? : string;
  redPlayicon? : boolean;
}


const videoBtnBefore = "before:content-[''] before:absolute before:w-[160%] before:h-[160%] ";

const Youtube: React.FC<Props> = ({ className, url, vimeoUrl, playSize = "sm", image, loading = "lazy", btntext, children, onlyPlayicon = false, redPlayicon = false, buttonPlay = false, videoStyle, ...props }) => {
  if (!url && !vimeoUrl)
    return <></>;
  const youtube = /https:\/\/(?:www\.youtube\.com\/embed\/|www\.youtube\.com\/watch\?v=|youtu\.be\/)([\w-]+)/ig.exec(url ?? "");
  const YouTubeID = !!youtube ? youtube[1] : undefined;
  const PopVideo = url?.includes("youtu") ? `https://www.youtube.com/embed/${YouTubeID}?autoplay=1` : url;
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const videocss = videoStyle == "rectangle" ? "rounded-lg" : "rounded-full";
  return (
    <div className={clsx(className)} {...props}>
      <div className={clsx(!onlyPlayicon && (redPlayicon ? "absolute inset-0 flex items-center justify-center mt-12" : `absolute inset-0 flex items-end`))}>
        {image && <Img className={clsx("absolute inset-0 mx-auto w-full h-full object-contain", videocss)} data={image} loading={loading} />}
        <div className={clsx(redPlayicon? "" : "if-button flex m-auto mb-5 justify-center lg:justify-end items-end w-full")} data-url={url?.includes("youtube") ? PopVideo : vimeoUrl ? vimeoUrl : ""} aria-label="Play">
          {onlyPlayicon ? <a onClick={onOpen} className="cursor-pointer -20 text-4xl text-white bg-green w-[40px] h-[40px] min-w-[40px] min-h-[40px] p-3 rounded-full flex justify-center items-center relative">
            <span className="animate-ping-slow group-hover:animate-none absolute inline-flex w-[30px] h-[30px] rounded-full bg-green opacity-100 -z-10"></span>
            <Icon name="play-sharp" />
          </a>
          :
          redPlayicon ? <a onClick={onOpen} className="cursor-pointer -20 text-4xl text-white bg-primary w-[90px] h-[90px] min-w-[40px] min-h-[40px] p-3 rounded-full flex justify-center items-center relative">
            <span className="animate-ping-slow group-hover:animate-none absolute inline-flex w-[58px] h-[58px] rounded-full bg-white opacity-100 -z-10"></span>
            <Icon name="play-sharp" />
          </a>
          :
          buttonPlay ?
          <a onClick={onOpen} className="btn flex justify-center font-semibold transition duration-300 cursor-pointer [&>*]:align-middle bg-primary border border-primary shadow-y-sm hover:bg-primary-hover active:bg-primary-btn-active text-white group/btn w-full sm:w-fit px-6 md:px-8 py-2 md:py-[13px] text-base rounded-full mt-12 mx-auto" href="#">
            <div className="h-full flex items-center justify-center">
              <span className="btn-text">{btntext}</span>
            </div>
          </a>
          :
          (url?.includes("youtu") || url?.includes("vimeo") ?
            <a onClick={onOpen} className={clsx(`cursor-pointer bg-white border rounded-full p-2 flex gap-4 max-w-[340px] lg:max-w-[70%] w-full lg:w-[378px] group shadow-md text-left items-center [&_p]:text-sm [&_p]:block z-10`)} href="#">
              <div className="z-20 text-4xl text-white bg-green w-[90px] h-[90px] min-w-[90px] min-h-[90px] p-8 rounded-full flex justify-center items-center relative">
                <span className="animate-ping-slow group-hover:animate-none absolute inline-flex w-[58px] h-[58px] rounded-full bg-green opacity-100 -z-10"></span>
                <Icon name="play-sharp" />
              </div>
              <div className="pr-4">{children}</div>
            </a>
            :
            <a className={clsx(`cursor-pointer bg-white border rounded-full p-2 flex gap-4 max-w-[340px] lg:max-w-[70%] w-full lg:w-[378px] group shadow-md text-left items-center [&_p]:text-sm [&_p]:block z-10`)} href={url}>
              <div className="z-20 text-4xl text-white bg-green w-[90px] h-[90px] min-w-[90px] min-h-[90px] p-8 rounded-full flex justify-center items-center relative">
                <span className="animate-ping-slow group-hover:animate-none absolute inline-flex w-[58px] h-[58px] rounded-full bg-green opacity-100 -z-10"></span>
                <Icon name="play-sharp" />
              </div>
              <div className="pr-4">{children}</div>
            </a>)
          }
          <Modal
            classNames={{
              base: "rounded-lg overflow-hidden",
              body: "p-0 pb-[56.25%] relative bg-black-dark",
              closeButton: "z-10"
            }}
            size="5xl"
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            scrollBehavior={`inside`}
          >
            <ModalContent>
              <ModalBody>
                <Spinner className="absolute inset-0 m-auto" size="lg" color="white" />
                <iframe className="absolute inset-0" src={url ? PopVideo : vimeoUrl ? vimeoUrl : ""} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" width="100%" height="100%" loading="lazy" />
              </ModalBody>
            </ModalContent>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Youtube;